<template>
  <v-card> 
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong> Counsellor Cum Technician</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
     <v-col cols="12" md="3">
				<v-text-field v-model="filter.name" label="Name" outlined dense hide-details></v-text-field>
			</v-col>
       <v-col cols="12" md="3" class="pl-0">
				<v-text-field v-model="filter.email" label="Email" outlined dense hide-details></v-text-field>
			</v-col>
      <v-col cols="12" md="3" class="pl-0">
				<v-text-field v-model="filter.phone_number" label="Phone No" outlined dense hide-details></v-text-field>
			</v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
				<v-btn color="primary" class="mr-2"  @click="CounselorSearchList()"> Search</v-btn>
        <v-btn color="primary"  @click="getCounselorList()">Reset</v-btn>
        </v-col>
         <!-- <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-8" @click="getCounselorList()"> Refresh</v-btn>
			</v-col> -->
		</v-row>

    <v-data-table
      :headers="headers"
      :items="counsellorList"
      :items-per-page="10"
      class="table-rounded"
      fixed-header
      height="400"
    >
      <template #[`item.operate`]="{item}">
				<v-btn color="primary" class="mr-3" @click="editCounseloerList(item)"> Edit </v-btn>
         <EditCunseloerList :visible="editCunseloer" :item="editItem" @close="editCunseloer = false" />
        <v-btn color="error" text @click="deleteCounellor(item)"> Delete </v-btn>
			</template>
    </v-data-table>

  <v-dialog v-model="DeleteModal" max-width="550">
		<v-card>
			<v-card-title class="text-h5"> Delete confirmation </v-card-title>
			<v-card-text> Are you sure you want to delete ? </v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn  text @click="DeleteModal = false"> Cancel </v-btn>
				<v-btn color="primary" @click="deleteCounselor(thisItemId)"> Confirm </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

   <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import CounsellorService from '@/service/Counsellor.service'
import EditCunseloerList from './EditCounsellor.vue'
const baseUrl = "https://api.stage.ebsr.in/counsellor"
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
import axios from 'axios'
export default {
   components: {
		EditCunseloerList,
	},
  data() {

    return {
      listLoading: false,
      DeleteModal: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      editItem: '',
      thisItemId:'',

      timeout: 2000,
      headers: [
        { text: 'NAME', value: 'name', width: '150px' },
        { text: 'EMAIL', value: 'email', width: '150px' },
        { text: 'PHONE NO.', value: 'phone_number', width: '150px' },
        { text: 'DISTRICT', value: 'district', width: '150px' },
        { text: 'DESIGNATION', value: 'designation', width: '200px' },
        { text: 'HOSPITAL', value: 'hospital_name', width: '150px' },
        { text: 'OPERATE', value: 'operate',width: '250px' },
      ],
      counsellorList : [],
      filter: {
        phone_number: '',
        email: '',
        name: '',
      },

      addUserModal: false,
      editCunseloer: false,
      
    }
  },
   watch: {
    options: {
      handler() {
        this.getCounselorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getCounselorList()
  },

  methods:{

    editCounseloerList(e) {
			this.editCunseloer = true
			this.editItem = e
		},
    deleteCounellor(e) {
			this.DeleteModal = true
			this.thisItemId = e.id
			// console.log("items", e)
		},


    async getCounselorList() {
      this.listLoading = true
      const service = new CounsellorService()
      let response = await service.counsellorList()

      if (response) {
        this.counsellorList = response.data
        this.totallist = this.counsellorList.length
         this.filter.name=''
        this.filter.email=''
        this.filter.phone_number=''
      } else {
        this.counsellorList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async deleteCounselor(item) {
			const data = {
				id: item,
			}
			try {
				const response = await axios.post(`${baseUrl}/delete`, data, {
					headers: {
						'Content-type': 'application/json',
				   	Authorization: `Bearer ${token}`,
					},
				})
				if (response.data.status == 200) {
					this.snackbarText = response.data.message
          this.getCounselorList()
          this.DeleteModal=false
				}else {
					this.snackbarText = response.data.message
				}
				this.snackbar = true
			} catch (e) {
				console.log(e)
			}
		},	

    async CounselorSearchList() {
      this.listLoading = true
      if(this.filter.name || this.filter.email || this.filter.phone_number){
			const data = {
				phone_number: this.filter.phone_number,
        email: this.filter.email,
        name: this.filter.name,
			}
			try {
				const response = await axios.post(`${baseUrl}/search`, data, {
					headers: {
						'Content-type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				})
				if (response) {
          this.counsellorList = response.data.data
          this.totallist = this.counsellorList.length
        } else {
          this.counsellorList = []
          this.totallist = 0
        }
        this.listLoading = false
			} catch (e) {
				console.log(e)
			}
    }else{
        
        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
		},	

  }
}
</script>
